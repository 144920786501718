import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { Grid, Button } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { getpdf } from "backendServices/ApiCalls";
import { useTranslation } from "react-i18next";

function PdfFile() {
	const {t} = useTranslation();
  const [manageallnews, setManageAllNews] = useState([]);
  const [pdfUrl, setPdfUrl] = useState([]);

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const GetallNews = () => {
    getpdf(
      (response) => {
        const processedData = response?.data?.data.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        setManageAllNews(processedData);
        setPdfUrl(response?.data?.url);
        if (response?.data?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetallNews();
  }, []);

  const columns = [
    {
      field: "index",
      headerName: `${t("pages.title.Sr")}`,
      width: 80,
    },
    {
      field: "name",
      headerName: `${t("pages.title.name")}`,
      width: 200,
    },
    {
      field: "pdf_file",
      headerName: `${t("pages.title.pdffile")}`,
      width: 200,
      groupable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
					size="small"
          onClick={() => handleViewPdf(params.row.pdf_file)}
        >
          {t("pages.title.viewdocument")}
        </Button>
      ),
    },
  ];

  const handleViewPdf = (fileName) => {
    const fileUrl = pdfUrl + fileName;
    window.open(fileUrl, "_blank");
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#8cda24",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={t("pages.title.importantDocuments")}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          <Box sx={{ height: 500, width: 1 }}>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={manageallnews}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
}

export default PdfFile;
