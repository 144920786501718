import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import { Alert, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Wallet } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { postRequest } from 'backendServices/ApiCalls';
import { useState } from 'react';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    backdropBlur: {
      backdropFilter: 'blur(8px)',
      '-webkit-backdrop-filter': 'blur(8px)', /* For Safari */
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zIndex.modal - 1,
    },
  }));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const UpgradePaymentOption = ({openPaymentOption,setOpen,setOpenPaymentOption,nextPackageAmount}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [BtnLoading, setBtnLoading] = useState(false)
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: "",
      });

    const PayWithEwallet = () =>{
        setBtnLoading(true)
        let params = {amount:nextPackageAmount}
        postRequest(
            '/upgradewithewallet',
            params,
            (response) => {
              if (response?.data?.status === 'success') {
                setalertData({
                    show: true,
                    message: `${t("warnings.upgradesuccess")}`,
                    variant: "success",
                  });
                  setBtnLoading(false)
                setOpenPaymentOption(false)
              }else{
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "error",
                  });
                  setBtnLoading(false)
                
              }
            },
            (error) => {
              console.log(error?.response?.data);
                setBtnLoading(false)

            }
          );
    }

    return (
            <Div>
                {alertData.show && (
                    <SweetAlert alertData={alertData} setalertData={setalertData} />
                )}
{openPaymentOption && <div className={classes.backdropBlur}></div>}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openPaymentOption}
                    onClose={()=>setOpenPaymentOption(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openPaymentOption}>
                        <Div sx={style}>

                        <Button onClick={()=>{setOpen(true); setOpenPaymentOption(false)}}  variant={"contained"} color={"warning"}  sx={{ fontWeight: '600',borderRadius:50,width:'100%'}} > 
                            {t("buttonsText.payWithCrypto")}
                        <img src={`${ASSET_IMAGES}/web3/eth.png`} alt="Image 1" style={{ marginLeft: 5, width:'20px' }} />
                        <img src={`${ASSET_IMAGES}/web3/matic.png`} alt="Image 2" style={{ marginLeft: 5, width:'20px' }} />
                        </Button>


                        <LoadingButton loading={BtnLoading} onClick={()=>{PayWithEwallet()}}  variant={"contained"} color={"warning"}  sx={{ fontWeight: '600',borderRadius:50,width:'100%', mt:2}} endIcon={<Wallet />} > 
                            {t("buttonsText.payEwallet")}
                        </LoadingButton>

                        <Alert severity='info' variant='outlined' sx={{mt:2}}>
                            {t("warnings.ewalletDiscount")}
                        </Alert>
                            
                        </Div>
                    </Fade>
                </Modal>
            </Div>
    );
};
export default UpgradePaymentOption;
