import React, { useState } from 'react';
import { TextField, Button, IconButton, InputAdornment, Alert } from '@mui/material';
import { ethers } from 'ethers';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { postRequest } from 'backendServices/ApiCalls';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';


const ConvertKey = () => {
  const { t } = useTranslation();
  const [mnemonic, setMnemonic] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [copied, setCopied] = useState(false);


  const handleGeneratePrivateKey = () => {
    setBtnLoading(true);
    postRequest(
      "/convertwallet",
      { phrase: mnemonic },
      (response) => {
        if (response?.data?.status === "success") {
          const generatedKey = response?.data?.data;
          setPrivateKey(generatedKey);
          setMnemonic('');
          setBtnLoading(false);
          setCopied(false);
        }else{
            alert('Invalid key phrase')
          setBtnLoading(false);

        }
      },
      (error) => {
        console.log(error?.response?.data);
        setBtnLoading(false);
      }
    );
  };



  const handleCopyPrivateKey = () => {
    navigator.clipboard.writeText(privateKey);
    setCopied(true); // Set the copied status to true
  };

  return (
    <div style={{ maxWidth: 400, margin: '0 auto', padding: '20px' }}>
      <TextField
        label={t("warnings.enterphrase")}
        variant="outlined"
        fullWidth
        value={mnemonic}
        onChange={(e) => setMnemonic(e.target.value)}
        margin="normal"
      />
      <LoadingButton
        loading={btnLoading}
        variant="contained"
        color="primary"
        onClick={handleGeneratePrivateKey}
        fullWidth
        style={{ marginBottom: '20px' }}
      >
        {t("warnings.generatekey")}
      </LoadingButton>
      {privateKey && (
        <>
          <Alert severity="info">{t("warnings.keysuccess")}</Alert>
          <TextField
            label="Private Key"
            variant="outlined"
            fullWidth
            value={privateKey}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCopyPrivateKey}>
                    {copied ? <CheckIcon color="success" /> : <ContentCopyIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            margin="normal"
          />
        </>
      )}
    </div>
  );
};

export default ConvertKey;
