import React, { useEffect } from "react";
import List from "@mui/material/List";
import { Autocomplete, Box, TextField, FormControl, Grid, Typography, Button } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";

import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import Div from "@jumbo/shared/Div/Div";
import { postRequest } from "backendServices/ApiCalls";
import { useState } from "react";
import { CKEditor } from "ckeditor4-react"; // Import CKEditor
import { useTranslation } from "react-i18next";
import { Chat } from "@mui/icons-material";

const validationSchema = yup.object({
  title: yup.string("Enter title").required("title is required"),
  message: yup.string("Enter message").required("message is required"),
});

const SupportCenter = () => {
  const {t} = useTranslation();


  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={t("sidebar.menuItem.helpcenter")}>

            <Typography variant={'h1'} sx={{textAlign:'center'}}>
            {t("pages.title.needhelp")}
            </Typography>
            <Typography variant="h6"  sx={{textAlign:'center'}}>{t("pages.title.supportTime")}</Typography>
        

            <Div style={{textAlign:'center'}}>
                <Button startIcon={<Chat />} href="javascript:void(Tawk_API.toggle())" variant="contained" color="primary" size="large" sx={{borderRadius:'50px', mt:4, color:'#fff'}} className='animate-scale'>
                    {t("pages.title.startlivechat")}
                </Button>
            </Div>
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default SupportCenter;
