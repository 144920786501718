import React from 'react'

function ElevatedAuth() {
  return (
    <div style={{textAlign:'center'}}>
        <p>Allow to login</p>
        <button>Alow</button>
        <button>Decline</button>
    </div>
  )
}

export default ElevatedAuth 