import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import {Alert, Button, Typography, Zoom} from "@mui/material";
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Warning } from '@mui/icons-material';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow TransitionComponent={Zoom}/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#000',
      color: '#fff',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));


const ChartWebsiteTraffic = ({capValue}) => {
    const { t } = useTranslation();

    return (
        <JumboCardQuick
        sx={{ textAlign: "center", color: "common.white" }}
      >  
      <Typography variant={"h4"} color={'white'}>{t("widgets.title.maxearningcap")}</Typography>

            <ReactSpeedometer
                value={capValue > 300 ? 300 : capValue}
                maxValue={300}
                needleTransition="easeElastic"
                textColor={'#fff'}
                currentValueText={`${t('widgets.title.currentcaplevel')}: ${capValue}`}
                ringWidth={24}
                needleColor={"#7ed11b"}
                needleHeightRatio={0.5}
                needleTransitionDuration={4000}
                valueTextFontSize={'16px'}
                valueTextFontWeight={"normal"}
                width={230}
                height={160}
                maxSegmentLabels={3}
                segmentColors={['#3bd2a2', '#44a08d', '#005a5b']}
                segments={300}

            />                
                {
                    capValue >= 300 ? (
                        <HtmlTooltip
                        title={
                          <React.Fragment>
                            <div dangerouslySetInnerHTML={{ __html: t("warnings.caplimithit", { trans: 'html' }) }}></div>
                          </React.Fragment>
                        }
                      >
                        <Button
                        variant="contained" size="small" sx={{borderRadius:'50px'}} color="warning" startIcon={<Warning />} >
                          {t("warnings.warning")}
                        </Button>
                    </HtmlTooltip>
                    )
                    :
                    null
                }
        </JumboCardQuick>
    );
};
/* Todo onlineUsers prop define */
export default ChartWebsiteTraffic;
