import React, { useState, useEffect, useContext } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import {
  Alert,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import imageCompression from 'browser-image-compression';
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useTranslation } from "react-i18next";
import { Instagram, Twitter } from "@mui/icons-material";
import Div from "@jumbo/shared/Div/Div";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { LoadingButton } from "@mui/lab";

const cryptocurrencies = ["MATIC (Polygon)"];

const SocialTasks = ({ setOpen,instagram, setInstagram,twitter, setTwitter,twitterUsername, setTwitterUsername,post, setPost,setSocialOpen }) => {
  const { t } = useTranslation();
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [btnLoading, setBtnLoading] = useState(false)
  // Step 4: Handle input change
  const handleInputChange = (event) => {
    setTwitterUsername(event.target.value);
  };
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const [errors, setErrors] = useState({
    instagram: "",
    twitter: "",
    post: "",
    twitterUsername:""
  });

  const handleUpload = async (e, field) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type.startsWith("image/")) {
        try {
          const options = {
            maxSizeMB: 1,          // Maximum file size in MB
            maxWidthOrHeight: 1024 // Maximum width or height
          };
          const compressedFile = await imageCompression(selectedFile, options);
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Data = reader.result; // Extract base64 encoded string
            if (base64Data) {
              if (field === "instagram") {
                setInstagram(base64Data);
                setErrors((prev) => ({ ...prev, instagram: "" }));
              } else if (field === "twitter") {
                setTwitter(base64Data);
                setErrors((prev) => ({ ...prev, twitter: "" }));
              } else if (field === "post") {
                setPost(base64Data);
                setErrors((prev) => ({ ...prev, post: "" }));
              }
              console.log("Image loaded successfully!");
            } else {
              console.log("Error loading image.");
            }
          };
          reader.readAsDataURL(compressedFile); // Read the compressed file as data URL
        } catch (error) {
          console.log("Error compressing image:", error);
          setalertData({
            show: true,
            message: "Error compressing image. Please try again.",
            variant: "error",
          });
        }
      } else {
        setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
      }
    }
  };


  const handleNext = () => {
    let errorMessages = { instagram: "", twitter: "", post: "" };
    let allUploaded = true;

    if (!instagram) {
      errorMessages.instagram = `${t("pages.title.instagramError")}`;
      allUploaded = false;
    }
    if (!twitter) {
      errorMessages.twitter = `${t("pages.title.twitterError")}`;
      allUploaded = false;
    }
    if (!post) {
      errorMessages.post = `${t("pages.title.postError")}`;
      allUploaded = false;
    }
    if(!twitterUsername)
      {
        errorMessages.twitterUsername = "Please enter your twitter username.";
        allUploaded = false;    
      }

    setErrors(errorMessages);

    if (allUploaded) {
      setBtnLoading(true)
      setTimeout(() => {
        setSocialOpen(false)
        setOpen(true);
      }, 2000)

    }
  };

  return (

        <JumboDemoCard
          title={t("pages.title.RequestWithdrawal")}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0, display:'unset' }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 250 }} aria-label="simple table">
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th">
                    <Typography variant="h6">{t("pages.title.FollowUsOnInstagram")}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      size="small"
                      href="https://www.instagram.com/elevatedmarketplace/"
                      target="_blank"
                      color="warning"
                      startIcon={<Instagram />}
                    >
                      {t("pages.title.Follow")}
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th">
                    <Typography variant="h6">{t("pages.title.FollowUsOnTwitter")}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      size="small"
                      href="https://twitter.com/elevated_nation"
                      target="_blank"
                      color="info"
                      startIcon={<Twitter />}
                    >
                      {t("pages.title.Follow")}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("pages.title.UseTheseHashtags")}</TableCell>
                  <TableCell>
                    #elevatednations #elevated #elevatedmarketplace
                    #elevatedNFTs #savagedogs #elevatedvisacard #엘리베이티드
                    #엘리베이티드네이션 #엘리베이티드마켓플레이스 #디지털국가
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                  <Alert variant="outlined" severity="warning" sx={{mt:1, color:'#FFC84C'}}>{t("pages.title.postAboutElevated")}</Alert>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h3" sx={{pt:2, ml:2}}>{t("pages.title.UploadScreenshots")}</Typography>
          <Grid container spacing={1} sx={{pl:2, pr:2}}>
            <Grid item xs={12} md={6}>
            <Typography variant="body1" color="white" sx={{ mt: 3 }}>
            {t("pages.title.UploadInstagramProof")}
            </Typography>
          <TextField
            onChange={(event) => {
              handleUpload(event, "instagram");
            }}
            required
            name="instagramProof"
            type="file"
            margin="normal"
            sx={{ marginBottom: "0px", width: "100%", mt: 0 }}
            InputProps={{
              endAdornment: (
                <CameraAltIcon fontSize={"small"} color={"success"} />
              ),
            }}
          />
          {errors.instagram && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {errors.instagram}
            </Typography>
          )}
          </Grid>
          <Grid item xs={12} md={6}>
          
          <Typography variant="body1" color="white" sx={{ mt: 3 }}>
          {t("pages.title.UploadTwitterProof")}
          </Typography>
          <TextField
            onChange={(event) => {
              handleUpload(event, "twitter");
            }}
            required
            name="twitterProof"
            type="file"
            margin="normal"
            sx={{ marginBottom: "0px", width: "100%", mt: 0 }}
            InputProps={{
              endAdornment: (
                <CameraAltIcon fontSize={"small"} color={"success"} />
              ),
            }}
          />
          {errors.twitter && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {errors.twitter}
            </Typography>
          )}
          </Grid>
          <Grid item xs={12} md={6}>

          <Typography variant="body1" color="white" sx={{ mt: 3 }}>
          {t("pages.title.UploadTwitterPostProof")}
          </Typography>
          <TextField
            onChange={(event) => {
              handleUpload(event, "post");
            }}
            required
            name="postProof"
            type="file"
            margin="normal"
            sx={{ marginBottom: "0px", width: "100%", mt: 0 }}
            InputProps={{
              endAdornment: (
                <CameraAltIcon fontSize={"small"} color={"success"} />
              ),
            }}
          />
          {errors.post && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {errors.post}
            </Typography>
          )}
          </Grid>

          <Grid item xs={12} md={6}>
          <Typography variant="body1" color="white" sx={{ mt: 3 }}>
            {t("pages.title.TwitterUsername")}
          </Typography>
          <TextField
            name="twitterUsername"
            type="text"
            onChange={handleInputChange} 
            margin="normal"
            sx={{ marginBottom: "0px", width: "100%", mt: 0 }}
            InputProps={{
              endAdornment: (
                <Twitter fontSize={"small"} color={"success"} />
              ),
            }}
          />
          {errors.twitterUsername && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {errors.twitterUsername}
            </Typography>
          )}
          </Grid>
            <LoadingButton loading={btnLoading} sx={{mt:2}} fullWidth variant="contained" color="primary" onClick={handleNext}>
              {t("pages.title.Next")}
            </LoadingButton>
            </Grid>
        </JumboDemoCard>

  );
};

export default SocialTasks;
