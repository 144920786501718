import React, { useState, useEffect } from 'react';
import {Grid} from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails, Typography  } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { faqdata } from 'backendServices/ApiCalls';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  faqContainer: {
    marginBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const Faq = () => {
  const classes = useStyles();
  const { t } = useTranslation();


    return (
      <Grid container spacing={2}  alignItems="center" justifyContent="center" >
      <Grid item sm={8} >
      <JumboDemoCard  title={"Frequently Asked Questions"}
                       wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
      <div>
        
      {Array.from({ length: 6 }, (_, index) => (
        <div key={index} className={classes.faqContainer}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{t(`faqs.q${index + 1}`)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t(`faqs.ans${index + 1}`)}</Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}

      
    </div>
    </JumboDemoCard>
      </Grid>
      </Grid>
    );
};

export default Faq;








