import React, { useContext } from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import {Alert, Button, Grid, Typography } from "@mui/material";
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import Div from '@jumbo/shared/Div/Div';
import { postRequest } from 'backendServices/ApiCalls';
import { useNavigate, useParams } from 'react-router-dom';
import PayPalButton from '../components/mui/Payments/Paypal';
import { useTranslation } from 'react-i18next';



const PaymentStatus = () => {


  const {status} = useParams();
 
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center" >

      <Grid item sm={8} >
        <JumboDemoCard
          wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
            alignItems="center"
          >
            <Alert severity={status === "success" ? 'success' : 'error'}>
                {
                   status === "success" ?
                   (
                    <div dangerouslySetInnerHTML={{ __html: t("warnings.paymentsuccess", { trans: 'html' }) }}></div>
                   )
                   :
                   (
                    <div dangerouslySetInnerHTML={{ __html: t("warnings.paymentcancel", { trans: 'html' }) }}></div>

                   ) 
                }
            </Alert>
          </Box>

        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default PaymentStatus;








