import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Div from "@jumbo/shared/Div";
import UpgradeForm from './UpgradeForm';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    backdropBlur: {
      backdropFilter: 'blur(8px)',
      '-webkit-backdrop-filter': 'blur(8px)', /* For Safari */
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zIndex.modal - 1,
    },
  }));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const UpgradeModal = ({open,setOpen,nextPackageAmount}) => {
    const classes = useStyles();
 

    return (
            <Div>
{open && <div className={classes.backdropBlur}></div>}

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={()=>setOpen(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Div sx={style}>
                           
                            <UpgradeForm setOpen={setOpen} nextPackageAmount={nextPackageAmount}/>
                        </Div>
                    </Fade>
                </Modal>
            </Div>
    );
};
export default UpgradeModal;
