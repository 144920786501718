import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import { Chip,Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useNavigate } from "react-router-dom";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
const UserRank = () => {
  const { t } = useTranslation();
  const { loginUserData, loading } = useContext(CustomProvider);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const nav = useNavigate()

  if (loading) {
    return "";
  }

  const handleClick = async (url) => {
    if (!loginUserData || !loginUserData.walletaddress) {

      await nav('/market-place')
    } else {
      // Open the link if the wallet address is not empty
      await nav(url)
    }
  };
  let imgpath = '' 
  if(loginUserData?.pkgid===21 || loginUserData?.pkgid===22){
     imgpath=getAssetPath(`${ASSET_IMAGES}/packages/ALPHA.png`, "102x102")

  }else{
     imgpath=getAssetPath(`${ASSET_IMAGES}/packages/OMEGA.png`, "102x102")

  }

  return (
    <JumboCardQuick
      sx={{ textAlign: "center", color: "common.white" }}
    >
        {alertData.show && (
          <SweetAlert alertData={alertData} setalertData={setalertData} />
        )}
      <Typography variant={"h2"} color={'white'}>{t("pages.title.CurrentRank")}</Typography>
      <Div
        sx={{ margin: "auto", minWidth: 0, justifyContent: "center", mb: 0,height:'130px', width:'120px', borderRadius:'20px', background:'#3bd2a2', pt:0, boxShadow:'1px 0px 15px #3bd2a2', overflow:'hidden' }}
      >
{
  loginUserData?.pkgid === 0 ? (
    <HourglassEmptyIcon sx={{ fontSize: 70 }} />
  ):
  (
    <img
      src={`${ASSET_IMAGES}/ranks/${loginUserData?.rankimage}`}
      alt={loginUserData?.ranks_name}
      width={'100%'}
    />
  )
}

      </Div>

{/* 
      <Typography variant={"h5"} color={"common.black"} sx={{ mt: 1, mb:1 }}>
        Joining Date:{" "}
        {loginUserData?.status === "approved"
          ? loginUserData?.joiningdate
          : "---"}
      </Typography> */}
{/* 
      <Typography variant={"body1"} color={"common.white"} sx={{ mb: "2px" }}>
        Renewal Date:{" "}
        {loginUserData?.membership !== "pending"
          ? expiryDate.toLocaleDateString("en-GB")
          : "N/A"}
      </Typography> */}

{
  loginUserData?.pkgid === 0 ? (
    <Button variant="contained" color="primary" onClick={()=>handleClick('/market-place')} >{t("sidebar.menuItem.GetYourNFTs")}</Button>
  )
  :
  (
    <Chip
      sx={{fontSize:16, fontWeight:'900', mt:1, mb:1}}
      label={loginUserData?.ranks_name}
      color={'success'}
      className="glowingButtonGold"

    />
  )
}

    </JumboCardQuick>
  );
};

export default UserRank;
