import React, { useEffect, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { Alert, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { postRequest } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import ConvertKey from "./ConvertKey";

const ChangeWallet = () => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState([]);
  const [loading, setLading] = useState(true);


  const GetallVideos = () => {
      let params= {"category":"Website Navigation"}
      postRequest('/getvideos',params,(response) => {
          setVideos(response?.data?.data);
          setLading(false)
      }, (error) => {
          console.log(error?.response?.data);
      })
  }

  useEffect(() => {
    GetallVideos();
  }, [])

  if (loading) {
    return (
      <Div
      sx={{
        display: "flex",
        minWidth: 0,
        alignItems: "center",
        alignContent: "center",
        height: "100%",
        justifyContent: "center", // Align items horizontally
        textAlign: 'center'
      }}
    >
      <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />

    </Div>
    );
  }

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
     
      <Grid item sm={8}>

    <JumboDemoCard
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Stack direction="column" spacing={2}  sx={{ width: '100%', }}>
        {/* <Stack direction="row" spacing={2}>
          <Button variant="contained"> <SimCardDownloadIcon/>{t("sidebar.menuItem.englishpdf")}</Button>
          <Button variant="contained"> <SimCardDownloadIcon/>{t("sidebar.menuItem.spanishpdf")}</Button>
        </Stack> */}
        {/* Embed YouTube video in a container for better control */}
        <Typography variant="h3" sx={{textAlign:'left'}}>{t("warnings.step1")}</Typography>
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/jXCz2U_uVi0?autoplay=1&controls=0&rel=0&modestbranding=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <Typography variant="h3" sx={{textAlign:'left'}}>{t("warnings.mobileguide")}</Typography>
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/0a4rTW_mDkE?autoplay=1&controls=0&rel=0&modestbranding=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <Typography variant="h3" sx={{textAlign:'left'}}>{t("warnings.textguideline")}</Typography>

        <Typography variant="h5"><div dangerouslySetInnerHTML={{ __html: t("warnings.stepsguide", { trans: 'html' }) }}></div></Typography>
        <Typography variant="h3"  sx={{textAlign:'left', mt:5, mb:5}}>{t("warnings.step2")}</Typography>

        <ConvertKey />
      </Stack>
    </JumboDemoCard>

      </Grid>
    </Grid>
  );
};

export default ChangeWallet;
