import React, { useEffect } from "react";
import List from "@mui/material/List";
import { Alert,Grid } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useContext } from "react";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { postRequest, updateProfileData } from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  amount: yup
    .number("Enter amount")
    .required("Amount is required"),
});


const UpgradeForm = ({setOpen,nextPackageAmount}) => {
  const { t } = useTranslation();
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  let userData = loginUserData;
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const onSubmitForm = (data, setSubmitting) => {
    let params = {
      amount: data.amount,
    };
    postRequest(
      '/createcharge',
      params,
      async (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: `${t("pages.title.Somethingwrong")}`,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          console.log(response?.data?.data?.data?.hosted_url)
          window.location.href = response?.data?.data?.data?.hosted_url



        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(async () => {
  }, [loginUserData]);

  return (
    <JumboCardQuick title={t("sidebar.menuItem.UpgradePackage")} sx={{ mt: 2 }}>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
        <Alert severity="info">
          {t("warnings.upgradeinfo")}
            
        </Alert>

      <List disablePadding>
        
  {
    loginUserData?.upgradeeligible === 'no'  ?
    (
        <Alert severity="warning">
            {t("pages.title.noteligible")}
        </Alert>
    )
    :
    (
        <Formik
          validateOnChange={true}
          enableReinitialize={true}
          initialValues={{
            amount: nextPackageAmount || 0,
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            onSubmitForm(data, setSubmitting);
          }}
        >
          {({ isSubmitting, values, setFieldValue, touched, errors }) => (
            <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
 
                </Grid>

                <Grid item xs={12}>
                  <JumboTextField
                    fullWidth
                    name="amount"
                    label={t("pages.title.EnterAmount")}
                    type="text"
                    inputProps={{readOnly: true}}

                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ width: { xs: "100%" }, justifyContent: "end" }}
                >
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    {t("pages.title.proceedtopayment")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
    )

}
        

      </List>
    </JumboCardQuick>
  );
};

export default UpgradeForm;
