import React, { useEffect, useState } from "react";
import PopularAgents from "../../shared/widgets/PopularAgents";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { roidata, referralusers, postRequest } from "backendServices/ApiCalls";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Div from "@jumbo/shared/Div/Div";
import { useTranslation } from "react-i18next";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import { LoadingButton } from "@mui/lab";
import { ExpandCircleDown, More } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import SearchGlobal from "app/shared/SearchGlobal";

export default function ViewReferrals({limit}) {
  const {t} = useTranslation();
  const { ...authOptions } = useJumboAuth();
  const [picturelink, setPictureLink] = useState([]);
  const [referralUsersData, setReferralUsersData] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [nextPage, setNextPage] = useState(0)
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation()
  let dlimit
  limit ? dlimit = limit : dlimit = 8



  const fetchReferralUsers = () => {
    postRequest('/referralusers', '',
      (response) => {
        setNextPage((prevPage) => prevPage + 1);
        setReferralUsersData((prevData) => [...prevData, ...response?.data?.data?.entries]);
        setPictureLink(response?.data?.data?.picturelink);
        setLoading(false);
        if (response?.data?.data?.entries.length < dlimit) {
          setBtnDisabled(true); // No more data to load from server
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchReferralUsers();
  }, []);

  useEffect(() => {
    setDisplayedUsers(referralUsersData.slice(0, displayedUsers.length + dlimit));
  }, [referralUsersData]);

  const loadMore = () => {
    if (displayedUsers.length < referralUsersData.length) {
      setDisplayedUsers(referralUsersData.slice(0, displayedUsers.length + dlimit));
    }
    if (displayedUsers.length + dlimit >= referralUsersData.length && !btnDisabled) {
      setBtnDisabled(true);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filteredUsers = referralUsersData.filter(user => 
      user.firstname.toLowerCase().includes(query.toLowerCase()) ||
      user.lastname.toLowerCase().includes(query.toLowerCase()) ||
      user.username.toLowerCase().includes(query.toLowerCase())
    );
    setDisplayedUsers(filteredUsers.slice(0, dlimit));
  };



  if (loading) {
    return (
      <Div
      sx={{
        display: "flex",
        minWidth: 0,
        alignItems: "center",
        alignContent: "center",
        height: "100%",
        justifyContent: "center", // Align items horizontally
        textAlign: 'center'
      }}
    >
      <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />

    </Div>
    );
  }

  return (
    <>
    <Grid item xs={12}>
    {
        location?.pathname === "/referrals" ? (
          <Grid container  
          justifyContent="center" 
          alignItems="center" 
          spacing={3}
          >
            <Grid sm={6} xs={12} mb={6} item>
              <SearchGlobal handleSearch={handleSearch} />
            </Grid>
          </Grid>
        )
        :
        null


    }

      <PopularAgents picturelink={picturelink} data={displayedUsers} />
    </Grid>
    {
        location?.pathname === "/referrals" ? (
          <Grid item sx={{mt:10, alignItems:'center', textAlign:'center'}}>
          <LoadingButton onClick={()=>loadMore()} disabled={btnDisabled} variant="contained" size="large" color="warning" endIcon={<ExpandCircleDown   />} loading={btnLoading}>Load More</LoadingButton>
          </Grid>
        )
        :
        null
      }
    </>
  );
}
