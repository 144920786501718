import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CryptoJS from 'crypto-js';

const TawkToWidget = () => {
  const {loginUserData} = useContext(CustomProvider);


  function hashInBase64(userId){
    const secretKey = '9c6d93cee506b1dcebcef52c23b082f1da50d17a';

    var hash = CryptoJS.HmacSHA256(userId, secretKey);

    return CryptoJS.enc.Hex.stringify(hash);
  }



  useEffect(() => {
    const loadTawkToScript = () => {
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/65f2bd679131ed19d979c637/1hou37nrc';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s1.onload = () => {
        window.Tawk_API = window.Tawk_API || {};
        if (loginUserData?.username && loginUserData?.username !== "") {
          window.Tawk_API.login({
            hash: hashInBase64(loginUserData?.userid),
            userId: loginUserData?.userid,
            name: loginUserData?.username,
            email: loginUserData?.email,
            phone: loginUserData?.mobile
          }, function (error) {
            console.log('tttttt',error);
            // do something if error
          });
        } else {

        }
      };
      s0.parentNode.insertBefore(s1, s0);
    };

    loadTawkToScript();

  }, [loginUserData]);

  return (
    <Helmet>
      {/* No need to add anything here since the script is added dynamically */}
    </Helmet>
  );
};

export default TawkToWidget;
